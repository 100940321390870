import '../../components/users/sessions/user-session-form';
import '../../utilities/setup-shoelace';
import '@shoelace-style/shoelace/dist/components/button/button';
import '@shoelace-style/shoelace/dist/components/icon/icon';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button';
import '../dashboard';
import { authenticityTokenInput } from 'utilities/csrf-token';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { OnePanelLoginLayout } from '../../components/one-panel-login-layout';
import { viewStyles } from '../../styles/view';

@customElement('new-user-session-view')
export class NewUserSessionView extends OnePanelLoginLayout {
  @property() oauthUrl!: string;
  @property() sessionUrl!: string;
  @property() nonprofitSignUpUrl!: string;

  renderContent() {
    return html`
      <h2>Welcome back</h2>
      <form id="oauth-form" method="post" action=${this.oauthUrl}>
        ${authenticityTokenInput()}
        <sl-button variant="default" size="large" @click=${() => this.onGoogleOauthSubmit()}>
          <sl-icon name="google" slot="prefix"></sl-icon>
          Sign in with Google
        </sl-button>
      </form>

      <div id='form-divider'>
        <div id='gray-line'></div>
        <p>or</p>
        <div id='gray-line'></div>
      </div>

      <user-session-form
        path=${this.sessionUrl}
      ></user-session-form>

      <p style="text-align: center; margin-top: 24px; margin-bottom: 0">Don't have an account?</p>
      <p style="text-align: center; margin-top: 10px;">
        <a href=${this.nonprofitSignUpUrl}>Claim your nonprofit</a> or <a href="https://getchange.io/request-a-demo">book a demo</a>
      </p>
    `;
  }

  static styles = [viewStyles, OnePanelLoginLayout.styles];

  onGoogleOauthSubmit() {
    (this.shadowRoot.getElementById("oauth-form") as HTMLFormElement).submit();
  }
}
