import { html, css, LitElement, CSSResultGroup } from 'lit';
import { query } from 'lit/decorators';
import '@shoelace-style/shoelace/dist/components/dialog/dialog';
import SlDialog from '@shoelace-style/shoelace/dist/components/dialog/dialog';

/**
 * This is a base class for dialogs.
 *
 * To use it, extend it and override the renderContent() method, which should return the content of the dialog.
 *
 * To get the _styles_, add Dialog.styles to your subclass' styles array, like this:
 * ---
 * import { Dialog } from 'Dialog';
 * export class MyDialog extends Dialog {
 *   static styles = [Dialog.styles, css`...my subclass styles...`]
 * }
 *
 */
export abstract class Dialog extends LitElement {
  show() { this.dialog.show(); }
  hide() { this.dialog.hide(); }

  // Override me.
  // This method should return the content of the dialog.
  abstract renderContent(): any;

  // Add a label (title) to the dialog.
  abstract label(): string;

  @query('sl-dialog') dialog: SlDialog;

  render() {
    return html`
      <sl-dialog
        .label=${this.label()}
      >
        ${this.renderContent()}
      </sl-dialog>
    `
  }

  static styles: CSSResultGroup = css`
    sl-dialog::part(title) {
      font-family: 'Manrope', sans-serif;
      font-weight: 600 !important;
    }
    sl-dialog::part(footer) {
      border-top: 2px solid var(--sl-color-neutral-50);
    }
    a {
      color: var(--sl-color-primary-600);
      text-decoration: none;
      font-weight: 500;
    }
    sl-progress-bar {
      --height: 5px;
      margin-bottom: var(--sl-spacing-medium);
    }
    #step-count {
      font-size: 0.8rem;
      color: var(--sl-color-neutral-400);
      margin: var(--sl-spacing-x-small) 0;
    }
  `;
}