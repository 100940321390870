import { css } from 'lit';

/**
  * These styles give the sl-badges lighter colors.
  */
export const lightBadgeStyles = css`
  sl-badge::part(base) {
    border: none;
  }
  sl-badge[variant=success]::part(base) {
    background-color: var(--sl-color-success-200);
    color: var(--sl-color-success-600);
  }
  sl-badge[variant=warning]::part(base) {
    background-color: var(--sl-color-warning-200);
    color: var(--sl-color-warning-600);
  }
  sl-badge[variant=danger]::part(base) {
    background-color: var(--sl-color-danger-200);
    color: var(--sl-color-danger-600);
  }
  sl-badge[variant=neutral]::part(base) {
    background-color: var(--sl-color-neutral-200);
    color: var(--sl-color-neutral-600);
  }
  sl-badge[variant=primary]::part(base) {
    background-color: var(--sl-color-primary-200);
    color: var(--sl-color-primary-600);
  }
`;
