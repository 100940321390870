import { css } from 'lit';

/**
  * These file-selector-button styles make the default file upload button look like
  * a shoelace button.
  */
export const fileInputStyles =  css`
  input[type=file]::file-selector-button {
    background-color: var(--sl-color-neutral-0);
    border-color: var(--sl-color-neutral-300);
    color: var(--sl-color-neutral-700);
    font-size: var(--sl-button-font-size-medium);
    height: var(--sl-input-height-medium);
    line-height: calc(var(--sl-input-height-medium) - var(--sl-input-border-width) * 2);
    padding: 0 var(--sl-spacing-medium);
    border-radius: var(--sl-input-border-radius-medium);
    display: inline-flex;
    align-items: stretch;
    justify-content: center;
    border-style: solid;
    border-width: var(--sl-input-border-width);
    font-family: var(--sl-input-font-family);
    font-weight: var(--sl-font-weight-semibold);
    text-decoration: none;
    user-select: none;
    white-space: nowrap;
    vertical-align: middle;
    transition: var(--sl-transition-fast) background-color, var(--sl-transition-fast) color, var(--sl-transition-fast) border, var(--sl-transition-fast) box-shadow;
    cursor: inherit;
    margin-right: var(--sl-spacing-medium);
  }
  input[type=file]::file-selector-button:hover {
    background-color: var(--sl-color-primary-50);
    border-color: var(--sl-color-primary-300);
    color: var(--sl-color-primary-700);
    cursor: pointer;
  }
  input[type=file]::file-selector-button:active {
    background-color: var(--sl-color-primary-100);
    border-color: var(--sl-color-primary-400);
    color: var(--sl-color-primary-700);
    cursor: pointer;
  }
`;