import { html, LitElement, css, nothing } from 'lit';
import { customElement, property, state, query, queryAll } from 'lit/decorators';
import { classMap } from 'lit/directives/class-map';
import SlCheckbox from '@shoelace-style/shoelace/dist/components/checkbox/checkbox';
import SlInput from '@shoelace-style/shoelace/dist/components/input/input';
import '@shoelace-style/shoelace/dist/components/input/input';
import '@shoelace-style/shoelace/dist/components/button/button';
import '@shoelace-style/shoelace/dist/components/checkbox/checkbox';
import '@shoelace-style/shoelace/dist/components/spinner/spinner';
import '../filter-button';
import { formatEin } from 'utilities/format-ein';

/**
 * Information about a Nonprofit to show in search results.
 */
interface Nonprofit {
  name: string
  ein: string
  mission: string
  id: string
  category: string
  ineligibility_reasons: string[]
  payable: boolean
};

/**
 * Nonprofit categories
 */
export const CATEGORIES = [
  'Arts and Culture',
  'Education',
  'Environment',
  'Animals',
  'Healthcare',
  'Human Services',
  'International Affairs',
  'Public Benefit',
  'Religion',
  'Mutual Benefit',
  'Unclassified',
];

/**
 * A search bar for nonprofits.
 */
@customElement('nonprofit-search-bar')
export class NonprofitSearch extends LitElement {
  /** Path to an icon to display in the search box. */
  @property() searchIcon: string

  /** Path to an icon to display in the "no results" box. */
  @property() noResultsIcon: string

  /** Displays suggested nonprofits below search box. */
  @property({ type: Boolean }) showSuggestions: boolean = false

  /** Displays the filter box to the right of the search box. */
  @property({ type: Boolean }) showFilters: boolean = false

  /** Adjusts the size of the search box */
  @property() size: 'small' | 'medium' | 'large' = 'medium'

  /** CSS styles to apply to the search box */
  @property() styles: string = '';

  /** Placeholder text for the search box */
  @property() placeholder: string = 'Search by name, EIN, or nonprofit ID'

  /** Results for the current search query + filters. */
  @state()
  private searchResults: Nonprofit[] = []

  /** Categories that are actively filtering results. */
  @state()
  selectedCategories = Array<string>()

  @state()
  loading = false

  @state()
  focused = false

  /** The search input box. */
  @query('sl-input[type=text]') searchInput!: SlInput

  @queryAll('sl-checkbox') categoryCheckboxes!: Array<SlCheckbox>

  /** Used for cancelling search API requests */
  searchTimeout: number|undefined

  constructor() {
    super();
    this.addEventListener('blur', (e) => {
      this.focused = false;
    });
  }

  render() {
    return html`
      <!-- Search box -->
      <div id="search-area">
        <sl-input
          type="text"
          placeholder=${this.placeholder}
          name="nonprofit-search"
          @sl-input=${this.performSearch}
          @sl-focus=${() => this.focused = true}
          size=${this.size}
          clearable
        >
          <img id="icon" slot="prefix" src=${this.searchIcon} />
          ${this.showFilters ? html`
            <filter-button slot="suffix" .appliedFiltersCount=${this.selectedCategories.length}>
              <div class="title">Categories</div>
              ${CATEGORIES.map(category => html`
                <div class='category-filter-row'>
                  <sl-checkbox value=${category} @sl-change=${() => this.handleCategoryFilterChange()}>${category}</sl-checkbox>
                </div>
              `)}
            </filter-button>
          ` : nothing}
        </sl-input>

      </div>

      <!-- Search suggestions -->
      ${this.showSuggestions && this.selectedCategories.length === 0 ? this.renderSuggestions() : ''}

      <!-- Search results -->
      ${this.noSearchTerm() || !this.focused ? nothing : this.renderSearchResults()}
    `;
  }

  renderSearchResults() {
    return html`
      <div id="search-results" tabindex="1">
        ${this.searchResults.length !== 0 ? html`
          <div id="search-results-title">Nonprofits</div>
          ${this.searchResults.map((nonprofit, i) => html`
            <a href="/nonprofits/${nonprofit.id}" class="search-result ${classMap({ ineligible: !nonprofit.payable })}">
              <div class="name">${nonprofit.name}</div>
              <div class="ein">${formatEin(nonprofit.ein)}</div>
            </a>
          `)}
        ` : nothing}
        ${this.searchResults.length === 0 && !this.loading ? html`
          <p id="no-results">
            <img src=${this.noResultsIcon}>
            <br>
            <br>
            <b>Sorry, this isn't a tax-deductible organization.</b>
            <br>
            <br>
            <span>Something wrong? <a href="mailto:support@getchange.io">Let us know</a>.</span>
          </p>
        ` : nothing}
        ${this.loading ? html`
          <div id="loading-overlay"><sl-spinner></sl-spinner></div>
        ` : nothing}
      </div>
    `;
  }

  private renderSuggestions() {
    return html`
      <div id="suggestions">
        Try...
        ${['Know Your Rights Camp', 'Watsi', 'One Tree Planted'].map(name => html`
          <button @click=${() => this.handleSuggestionClick(name)}>${name}</button>
        `)}
      </div>
    `;
  }

  /**
   * Add the given category to the active filters.
   */
  private handleCategoryFilterChange() {
    this.selectedCategories = [...this.categoryCheckboxes].filter(checkbox => checkbox.checked).map(checkbox => checkbox.value);
    this.performSearch();
  }

  private handleSuggestionClick(searchSuggestion) {
    this.searchInput.focus();
    this.searchInput.value = searchSuggestion;
    this.performSearch();
  }

  /**
   * Search nonprofits given the current state of the search input box and filters.
   */
  private performSearch() {
    const name = this.searchInput.value;
    if (name === '' && this.selectedCategories.length === 0) {
      this.loading = false;
      this.searchResults = [];
      return;
    }

    this.loading = true;
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = window.setTimeout(() => {

      const queryParams = new URLSearchParams();
      queryParams.append('search_term', name);
      for (const category of this.selectedCategories) {
        queryParams.append('categories[]', category);
      }

      fetch(`/api/v1/nonprofit_basics?${queryParams.toString()}`, {
        headers: {
          'Content-Type': 'application/json'
        }})
        .then(response => response.json())
        .then(response => {
          this.searchResults = response.nonprofits as Nonprofit[];
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }, 200);
  }

  private noSearchTerm() {
    return !this.searchInput || this.searchInput.value === null || this.searchInput.value === '';
  }

  static styles = css`
    :host {
      display: block;
      position: relative;
    }
    #search-area {
      display: flex;
    }
    sl-input {
      flex: 1;
    }
    sl-input::part(base) {
      border-radius: var(--border-radius, 0px);
      height: var(--height, 100%);
      display: flex;
      align-items: center;
    }
    #no-results {
      text-align: center;
      color: #6B6B6B;
      color: gray;
      max-width: 480px;
      margin: 0 auto;
      padding: 24px 0;
    }
    #search-results {
      padding: 24px;
      border: 1px solid black;
      position: absolute;
      width: 100%;
      top: 50px;
      box-sizing: border-box;
      background: white;
      border-radius: 8px;
      border: 1px solid #C1C1C1;
      box-shadow: var(--sl-shadow-x-large);
      min-height: 100px;
      z-index: 1;
    }
    #search-results-title {
      color: #444444;
      text-transform: uppercase;
      margin-bottom: 3px;
      font-size: 0.9em;
    }
    .search-result {
      display: flex;
      padding: 9px;
      margin: 0 -9px;
      border-radius: 6px;
      color: inherit;
      text-decoration: none;
      background-color: white;
      transition: background-color 0.15s linear;
    }
    .search-result:hover {
      background-color: #F7F7FC;
    }
    .search-result.ineligible {
      color: var(--sl-color-neutral-400);
    }
    .search-result .name {
      font-weight: 600;
      text-align: left;
      flex: 1;
    }
    .search-result .ein {
      color: #A1A1A1;
      margin-left: 12px;
    }
    #loading-overlay {
      position: absolute;
      display: flex;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.5);
      align-items: center;
      z-index: 10;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    sl-spinner {
      font-size: 2em;
    }

    filter-button .title {
      color: #949494;
      text-transform: uppercase;
      font-size: 0.9em;
      margin-bottom: 4px;
    }
    .category-filter-row {
      margin-top: 8px;
    }

    filter-button {
      margin-left: 12px;
    }
    filter-button::part(dropdown-container) {
      width: 200px;
    }

    #suggestions {
      padding: 12px 3px;
    }
    #suggestions button {
      padding: 0;
      cursor: pointer;
      background: none;
      color: inherit;
      font-family: inherit;
      border: none;
      font-size: inherit;
      color: var(--sl-color-primary-500);
      margin-left: 12px;
    }
  `;
}

