import { html, LitElement, css, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators';
import { csrfToken } from 'utilities/csrf-token';
import { lightBadgeStyles } from 'styles/light-badges';
import { Notification } from 'types';
import '@shoelace-style/shoelace/dist/components/badge/badge';
import '@shoelace-style/shoelace/dist/components/button/button';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button';
import '@shoelace-style/shoelace/dist/components/menu/menu';
import '@shoelace-style/shoelace/dist/components/spinner/spinner';
import '@shoelace-style/shoelace/dist/components/skeleton/skeleton';

/**
 * A search bar for nonprofits.
 */
@customElement('notification-bell')
export class NotificationBell extends LitElement {
  @property() defaultIcon: string;

  @state() notificationsCount: number = 0;
  @state() notificationsUnread: boolean = false;
  @state() notifications: Notification[] = [];
  @state() loading = false;

  firstUpdated() {
    // retrieve notification details
    fetch('/api/v1/notifications/count', {
      method: 'GET',
      headers: { 'X-CSRF-Token': csrfToken() }
    })
      .then(response => {
        if (!response.ok) throw new Error('Failed to retrieve notification count.')
        return response.json();
      })
      .then(data => {
        this.notificationsCount = data.count;
        this.notificationsUnread = data.unread;
      })
      .catch(() => {
        this.notificationsCount = 0;
        this.notificationsUnread = false;
      });
  }

  render() {
    const unreadNotifications = this.notifications.reduce((acc, notification) => acc + (notification.read ? 0 : 1), 0);

    return html`
      <sl-dropdown @sl-show=${this.retrieveNotifications} @sl-hide=${this.removeNotificationSymbol}>
        <div slot="trigger">
          <sl-icon-button name="bell-fill"></sl-icon-button>
          ${this.notificationsUnread ? html`
            <div id="notification-bell-marker">
              <div></div>
            </div>` : nothing}
        </div>
        <sl-menu>
          <div class="title">
            <h3>Notifications</h3>
            ${unreadNotifications > 0 ? html`
              <sl-badge variant="primary">${unreadNotifications} new</sl-badge>
            ` : nothing}
          </div>
          <sl-divider></sl-divider>
          <div class="notifications">
            ${this.loading ? new Array(this.notificationsCount).fill(null).map((_, i) => {
              return this.renderSkeletonNotification(i < this.notificationsCount - 1)
            }) : this.notifications && this.notifications.length > 0 ? this.notifications.map((notification, i) => (
              this.renderNotification(notification, i < this.notifications.length - 1)
            )) : html`
              <div class="no-notifications-display">
                <p>No notifications found.</p>
              </div>
            `}
          </div>
          <sl-divider></sl-divider>
        </sl-menu>
      </sl-dropdown>
    `;
  }

  private renderSkeletonNotification(divider: boolean = true) {
    return html`
      <div class="notification">
        <sl-skeleton class="image"></sl-skeleton>
        <div>
          <sl-skeleton class="date" style="width: 50px;"></sl-skeleton>
          <sl-skeleton class="message" style="width: 250px;"></sl-skeleton>
        </div>
      </div>
      ${divider ? html`<sl-divider></sl-divider>` : nothing}
    `
  }

  private renderNotification(notification: Notification, divider: boolean = true) {
    return html`
      <div
        class="notification${!notification.read ? ' unread' : ''}${notification.url ? ' clickable' : ''}"
        @click=${(_e) => this.navigateToLink(notification.url)}
      >
        ${notification.icon_url !== null && notification.icon_url !== '' ? html`
          <img class="image" src=${notification.icon_url}></img>
        ` : html`
          <div class="image" style="display: flex; align-items: center; justify-content: center;">
            <img style="width: 40px; height: 40px;" src=${this.defaultIcon}></img>
          </div>
        `}
        <div>
          <p class="date">${this.renderDate(notification.updated_at)}</p>
          <p class="message" .innerHTML=${notification.message}></p>
        </div>
      </div>
      ${divider ? html`<sl-divider></sl-divider>` : nothing}
    `
  }

  private retrieveNotifications() {
    this.loading = true;

    fetch('/api/v1/notifications', {
      method: 'GET',
      headers: { 'X-CSRF-Token': csrfToken() }
    })
      .then(response => {
        if (!response.ok) throw new Error('Failed to retrieve notifications.')
        return response.json();
      })
      .then(data => {
        this.notifications = data;
        this.notificationsCount = data.length;
        this.notificationsUnread = data.some(notification => !notification.read);
      })
      .catch(() => {
        this.notifications = [];
        this.notificationsCount = 0;
        this.notificationsUnread = false;
      })
      .finally(() => this.loading = false);
  }

  private navigateToLink(link: string) {
    if (!link) return;
    window.location.href = link;
  }

  private renderDate(timestamp) {
    if (!timestamp) return '';

    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('default', { month: 'short', day: 'numeric' });
  }

  private removeNotificationSymbol() {
    this.notificationsUnread = false;
  }

  static styles = [
    lightBadgeStyles,
    css`
      div[slot=trigger] {
        position: relative;
      }
      p, sl-divider {
        margin: 0;
      }
      sl-icon-button {
        font-size: 24px;
      }
      sl-menu {
        padding: 0;
      }
      sl-spinner {
        font-size: 24px;
      }
      .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        gap: 16px;
        padding: 12px 24px;
      }
      .clickable {
        cursor: pointer;
      }
      .clickable:hover {
        background-color: var(--sl-color-blue-100);
      }
      .clickable:active {
        background-color: var(--sl-color-blue-200);
      }
      .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 84px;
        font-size: 14px;
      }
      .notifications {
        max-height: 60vh;
        width: 366px;
        overflow: auto;
      }
      .notification {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 18px 24px;
        gap: 12px;
      }
      .notification .image {
        border-radius: 50%;
        height: 48px;
        width: 48px;
      }
      .notification .date {
        font-size: 11px;
        margin-bottom: 4px;
      }
      .notification .message {
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .no-notifications-display {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2px;
        height: 84px;
        font-size: 14px;
      }
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .title > h3 {
        margin: 12px 0px 12px 24px;
      }
      .unread {
        background-color: var(--sl-color-blue-50);
      }
      #notification-bell-marker {
        position: absolute;
        top: 0.45rem;
        right: 0.45rem;
        background-color: white;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      #notification-bell-marker > div {
        background-color: red;
        height: 9px;
        width: 9px;
        border-radius: 50%;
      }
    `
  ];
}
