import { LitElement, html, nothing  } from 'lit';
import { property, state } from 'lit/decorators';
import SlButton from '@shoelace-style/shoelace/dist/components/button/button';

export class UserForm extends LitElement {
  @property({type: String})
  authenticityToken: string

  @property({type: String})
  path: string

  @state()
  errors = {}

  @state()
  loading = false

  @state()
  disabled = false

  method: 'POST'|'PUT' = 'POST';

  protected handleSubmit(event) {
    event.preventDefault();
    this.loading = true;
    this.disabled = true;
    const formData = new FormData(event.target);
    let success = false;
    return fetch(this.path, {
      method: this.method,
      body: formData
    })
    .then(response => response.json())
    .then(response => {
      if (response.redirect_to) {
        success = true;
        window.location.href = response.redirect_to;
        return {};
      }
      return response;
    })
    .then(result => {
      // If this endpoint doesn't succeed, it should return an errors object.
      this.errors = result;
    })
    .catch(() => {})
    .finally(() => {
      // When the sign in/up is successful, the browser takes a moment to
      // perform the redirect. During that time, keep the button in a loading
      // state (do not set loading to false).
      if (!success) {
        this.loading = false;
        this.disabled = false;
      }
    });
  }

  protected errorMessages(fieldname: string) {
    if (this.errors[fieldname]) {
      return this.errors[fieldname].map(message => html`
        <div class="input-error">${message}</div>
      `);
    }
    return nothing;
  }
}