import { html, LitElement, css, CSSResultGroup, nothing, TemplateResult } from 'lit';
import { property } from 'lit/decorators.js';

/**
 * A layout for login and registration screens.
 */
export abstract class OnePanelLoginLayout extends LitElement {
  @property() logoUrl!: string;
  @property() backgroundPatternPath!: string;

  render() {
    return html`
      <div id="container" style="background: center / cover url(${this.backgroundPatternPath}), #2E1705;">
        <div class="logo-container">
          <img src="${this.logoUrl}" alt="Company Logo" class="company-logo">
        </div>
        <div id="form-container">
          ${this.renderContent()}
        </div>
      </div>
    `;
  };

  protected abstract renderContent(): unknown;

  static styles: CSSResultGroup = [
  css`
    #container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
    }
    a, a:visited {
      font-weight: 500;
    }
    h2 {
      color: black;
      font-size: 22px;
      text-align: center;
    }
    .logo-container {
      margin: 25px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .company-logo {
      max-width: 132px;
    }
    #form-container {
      padding: 12px 40px;
      background: white;
      border-radius: var(--sl-border-radius-x-large);
      box-shadow: var(--sl-shadow-large);
      width: 100%;
      max-width: 400px;
    }
    #form-divider p {
      color: var(--sl-color-neutral-400);
      font-size: 15px;
      margin: 9px 0px;
      font-weight: 300;
    }

    #oauth-form{
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      margin-bottom: 17px;
    }

    #form-divider {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

    }
    #gray-line {
      width: 40%;
      background-color: #d1d5db;
      height: 1px;
    }
  `];
}

