import { html, css } from 'lit';
import { customElement } from 'lit/decorators';
import { UserForm } from '../user-form';
import '@shoelace-style/shoelace/dist/components/input/input';
import '@shoelace-style/shoelace/dist/components/button/button';
import '@shoelace-style/shoelace/dist/components/spinner/spinner';
import { csrfToken } from 'utilities/csrf-token';

@customElement('user-session-form')
export class UserSessionForm extends UserForm {
  render() {
    return html`
      <form @submit=${e => this.handleSubmit(e)}>
        ${this.errorMessages('authentication')}

        <sl-input type="text" style="display: none;" name="authenticity_token" value=${csrfToken()}></sl-input>

        <sl-input label="Email" name="user[email]" autofocus autocomplete="email" placeholder="hello@getchange.io"></sl-input>

        <sl-input label="Password" name="user[password]" type="password" autocomplete="current-password" placeholder="••••••"></sl-input>

        <div style="margin-top: 2px;">
          <a id="forgot-password-button" href="/reset_password">Forgot your password?</a>
        </div>

        <div class="actions">
          <sl-button type="submit" variant="primary" size="large" ?loading=${this.loading}>Continue</sl-button>
        </div>
      </form>
    `;
  }

  static styles = css`
    .input-error {
      color: red;
      font-size: 13px;
    }
    sl-input, sl-button, sl-checkbox {
      margin-top: 14px;
    }
    .actions {
      text-align: right;
    }
    sl-button[type=submit] {
      width: 100%;
    }
    sl-button[variant=text]::part(base), a {
      color: var(--sl-color-primary-600)
    }
    sl-button[variant=text]:hover::part(base), a:hover {
      color: var(--sl-color-primary-500)
    }
    sl-button[variant=text]:active::part(base), a:active {
      color: var(--sl-color-primary-400);
    }
    #forgot-password-button {
      font-size: 14px;
      font-weight: 500;
    }
  `;
}
