import { html } from 'lit';
/**
 * Get the Rails-provided CSRF token.
 * This token should be included with any asynchronous fetches to the Change backend.
 * 
 * When using rails form helpers, like <%= form_for %>, this token is automatically
 *   inserted for you. No need to use this function.
 * When using `fetch` asynchronously from JS, you have to manually attach the CSRF
 *   token in the header yourself. Add the header: `'X-CSRF-Token': csrfToken()` to
 *   your fetch request to authenticate it.
 */
export function csrfToken(): string {
  return (document.querySelector("[name='csrf-token']") as HTMLMetaElement)?.content;
}

export function authenticityTokenInput() {
  return html`<input type='hidden' value=${csrfToken()} name='authenticity_token'/>`;
}