import { css } from 'lit';

export const tableStyles = css`
table {
  border-spacing: 0;
  width: 100%;
  border: 1px solid #e4e8ed;
  border-radius: var(--sl-border-radius-large);
  overflow: hidden;
}
table thead {
  background-color: var(--color-pebble-100);
}
table th {
  text-transform: capitalize;
  color: var(--color-pebble-500);
  font-weight: 500;
  text-align: left;
  font-size: 0.8em;
  padding: 16px 10px;
  /* Avoid selecting header when clicking column sort arrows */
  user-select: none;
}
table th:first-child {
  padding-left: 20px;
}
table th:last-child {
  padding-right: 20px;
}
table tr {
  border-left: 1px solid #e4e8ed;
  border-right: 1px solid #e4e8ed;
}
table th, table td {
  white-space: nowrap;
  border-bottom: 1px solid #e4e8ed;
}
table tr:last-child td {
  border-bottom: none;
}
table td {
  padding: 10px;
  vertical-align: middle;
}
table td:first-child {
  padding-left: 20px;
}
table td:last-child {
  padding-right: 20px;
}
table tbody tr:hover, table tbody tr:focus, table tbody tr.hover {
  background-color: #f7fafc;
}
table a.unstyled-link {
  margin: 0;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  text-align: inherit;
  text-decoration: none;
  background-color: initial;
  border: 0;
  outline: none;
  cursor: pointer;
}
table + pagination-controls, .empty-table + pagination-controls {
  margin-top: 20px;
}
.empty-table {
  text-align: center;
  color: #858585;
  background-color: #F8F8F8;
  padding: 20px;
  border-radius: 8px;
  font-weight: 300;
}
.empty-table a {
  color: inherit;
}
sl-tab-group.table-filters {
  --track-width: 1px;
  bottom: calc(-1 * var(--track-width) - 1px);
  margin-bottom: 10px;
}
sl-tab-group.table-filters::part(active-tab-indicator) {
  --track-width: 2px;
  bottom: -1px;
}
sl-tab-group.table-filters sl-tab::part(base) {
  padding: var(--sl-spacing-x-small) var(--sl-spacing-x-small);
}
table sl-badge.fill,
table sl-badge.fill::part(base) {
  width:100%;
}
`;