import { css } from 'lit';

/**
 * Generic view styles.
 */
export const viewStyles =  css`
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Manrope', sans-serif;
    color: var(--sl-color-neutral-900);
  }
  /**
   * Fixes a breaking change introduced in 2.5.0: https://github.com/shoelace-style/shoelace/pull/1317
   */
  sl-button[variant=text]::part(base) {
    min-height: unset;
  }

  sl-tab-group::part(active-tab-indicator) {
    transition: none;
  }
  
  a {
    color: var(--sl-color-primary-600);
    text-decoration: none;
  }
`;