/**
 * Tell Shoelace elements where to find assets.
 * This script should be loaded wherever Shoelace elements are used. For some reason, it does
 * not play nice when loaded globally.
 * 
 * "Assets", as of v2.0, is just "icons". E.g., the chevron in the select element,
 * or the "x" in the dialog element.
 * 
 * This script is inspired by the instructions at https://shoelace.style/tutorials/integrating-with-rails.
 */
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';
const rootUrl = (document.currentScript as HTMLScriptElement).src.replace(/\/packs.*$/, '');
setBasePath(rootUrl + '/packs/js/');