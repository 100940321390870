import { html, LitElement, css, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators';

import '@shoelace-style/shoelace/dist/components/button/button';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown';
import '@shoelace-style/shoelace/dist/components/icon/icon'

/**
 * A filter button with a dropdown.
 */
@customElement('filter-button')
export class FilterButton extends LitElement {
  @property({ type: Number }) appliedFiltersCount = 0

  render() {
    return html`
      <sl-dropdown placement="bottom-end" distance="10" hoist>
        <sl-button
          slot="trigger"
          id="filters-button"
          size="medium"
          variant="text"
          class=${this.appliedFiltersCount == 0 ? 'no-filters' : ''}
        >
          <sl-icon name="filter"></sl-icon>
        </sl-button>
        <div id="dropdown-container" part="dropdown-container">
          <slot></slot>
        </div>
      </sl-dropdown>
    `;
  }

  updated(changedProperties: PropertyValues) {
    if (changedProperties.has('appliedFiltersCount')) {
      // Whenever appliedFiltersCount changes, the width of the filter button changes.
      // If the dropdown is open, the dropdown's position needs to be recalulated, since
      // its position depends partially on the width of the filter button.
      this.shadowRoot.querySelector('sl-dropdown').reposition();
    }
  }

  static styles = css`
    sl-button::part(label) {
      display: flex;
      align-items: center;
      padding: 0px;
    }
    sl-icon {
      font-size: 24px;
    }
    .no-filters::part(base) {
      color: var(--sl-color-gray-500);
    }
    #dropdown-container {
      padding: 24px;
      background-color: white;
      border-radius: var(--sl-border-radius-large);
    }
    #filter-count {
      display: inline;
      background-color: var(--sl-color-primary-500);
      color: white;
      font-weight: 700;
      border-radius: 999px;
      padding: 3px 9px 3px 8px;
      margin-left: 3px;
    }
  `;
}

